import * as React from 'react';
import Layout from '../components/layout/layout';
import '../assets/css/main.scss';
import {graphql} from 'gatsby';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {StaticImage} from 'gatsby-plugin-image';
import Hero from '../components/hero/hero';

const IndexPage = () => {
  const { language } = useI18next();
  return (
    <Layout pageTitle={'Leendert de Kok'}>
      <>
        <Hero size={'normal'}>
          <div className={'me'}>
            <StaticImage placeholder={'none'} src="../assets/images/leendert-de-kok.png" alt="image of Leendert de kok"/>
          </div>
        </Hero>
        {
          language === 'nl' &&
          <div className="quote">
            <blockquote>
              "Slimme en betrouwbare oplossingen, daar word ik blij van!"
            </blockquote>
            <figcaption>
              <h1>
                <span>Leendert de Kok</span>
                <span>Freelance software ontwikkelaar</span>
              </h1>
            </figcaption>
            <p>
              Als freelance software ontwikkelaar creëer ik op maat gemaakte software <b>oplossingen</b>, met expertise in applicatieontwikkeling, JavaScript, en frontend ontwikkeling.
            </p>
            <p>
              Met enthousiasme voor techniek werk ik aan maatwerk softwareoplossingen. Mijn focus ligt op een <b>open</b> samenwerking met mijn opdrachtgevers, om wederzijdse tevredenheid te bereiken.
            </p>
            <p>
              Met mijn vaardigheden in app-ontwikkeling en frontend technologieën help ik u om uw <b>doelen</b> te bereiken en een eindproduct te realiseren dat volledig aan uw verwachtingen voldoet.
            </p>
          </div>
        }
        {
          language === 'en' &&
          <div className="quote">
            <blockquote>"Smart and reliable solutions, that makes me happy!"</blockquote>
            <figcaption>
              <h1>
                <span>Leendert de Kok</span>
                <span>Freelance software developer</span>
              </h1>
            </figcaption>
            <p>
              As a freelance software developer, I create tailor-made software <b>solutions</b>, specializing in application development, JavaScript, and frontend development.
            </p>
            <p>
              With enthusiasm for technology, I work on custom software solutions. My focus is on an <b>open</b> collaboration with my clients to achieve mutual satisfaction.
            </p>
            <p>
              With my skills in app development and frontend technologies, I can help you achieve your <b>goals</b> and deliver a final product that fully meets your expectations.
            </p>
          </div>
        }
      </>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
